@import './variables';

.rb-font {
  font-family: "Karla";
}

.rb-clr-green {
  color: #0F9D58 !important;
}

.rb-component-holder {
  margin-left: -15px;
  margin-right: -15px;
}

.rb-title {
  font-weight: 700;
  text-align: center;
  letter-spacing: -1px;
}

.rb-footer {
  background: $background-color;
  position: fixed;
  bottom: 3.6rem;
  padding: 1rem 2rem;
  width: 100%;
  z-index: 100;
}

.rb-details {
  margin-right: -15px;
  margin-left: -15px;
}

.rb-valid {
  background-image: linear-gradient(to bottom, #F8FDFF 0%, #F8FDFF 100%), linear-gradient(to bottom, #F8FDFF 0%, #F8FDFF 100%);
  background-clip: content-box, padding-box;
}

.rb-error {
  background-image: linear-gradient(to bottom, #FFF2F2 0%, #FFF2F2 100%), linear-gradient(to bottom, #FFF2F2 0%, #FFF2F2 100%);
  background-clip: content-box, padding-box;
}

.rb-section-body {
  margin-top: 8px;
}

.rb-section-label {
  font-size: larger;
  font-weight: 700;
}

.rb-block-style {
  padding: 1rem;
  background-color: #f8f9fa;
}

.rb-section-valid {
  border-radius: 0 0 2px 2px;
  border: 1px solid #ddd;
}

.rb-section-error {
  border-color: #EE4751;
  border-width: 2px;
  border-style: dashed;
  border-radius: 3px;
  border-top: hidden;
}

.rb-section-notapply {
  padding: 1rem;
  border-radius: 2px;
  border: 2px dashed #EE4751;
  background-color: #FCF5F5;
  border-top: hidden !important;
  opacity: 35%;
}

.rb-sec-hdr {
  border-radius: 2px 2px 0 0;
  border: 1px solid #ddd;
  border-bottom: hidden;
  background-color: #ececec;
  height: auto;
}

.rb-sec-hdr-notapply {
  background-color: #EE4751;
  border: 2px dashed #EE4751;
  border-bottom: hidden !important;
  height: auto;
  opacity: 35%;
  color: #FFFFFF;
}

.rb-sec-hdr-error {
  border-color: #EE4751;
  border-width: 2px;
  border-bottom: hidden !important;
  border-style: dashed;
  border-radius: 3px;
}

.rb-non-zero {
  .mat-badge-content {
    background-color: $primary-color !important;
    color: white !important;
  }
}

.rb-zero {
  .mat-badge-content {
    background-color: #eeeeee !important;
    color: #797979 !important;
  }
}

.rb-sectin-action {
  padding-right: 12px;
  cursor: pointer;
}

.rb-section-sub {
  background: #E9ECEF;
  padding: 5px 15px;
  color: rgba(0, 0, 0, 0.34);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.439px;
}

.rb-add-btn-style {
  border: 1px solid #ddd;
  border-radius: 1px;
}

.rb-add-btn-text-case {
  text-transform: uppercase;
}

.rb-menu-btn {
  border-radius: 0px !important;
  background-color: rgba(15, 157, 88, 1);  
  color: white;
  border-color: rgba(15, 157, 88, 1);  
}

.rb-menu-btn:hover{
  color: white;
}

.rb-menu {
  .mat-mdc-menu-item {
    background-color: #60cc70 !important;
    color: white !important;
    
  }

  .mat-mdc-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    color: #60cc70 !important;
    background-color: white !important;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0 !important;
    max-height:200px;
    overflow-y: auto;
  }
}

.rb-no-items {
  margin-top: 8px;
  min-height: 75px;
  text-align: center;
  vertical-align: middle;
  border: 2px dashed #797979;
  color: #797979;
  display: flex;
  background-color: #f8f9fa;
}

.rb-no-items-dotted-border {
  margin-top: 8px;
  border: 2px dashed #0F9D58;
  min-height: 75px;
  text-align: center;
  vertical-align: middle;
  color: #797979;
  display: flex;
  background-color: #f8f9fa;
}

.rb-text-right {
  text-align: right;
}

.rb-add-file {
  background-color: #fafafa;
  box-shadow: none !important;
  min-width: 36px !important;
  height: 42px !important;
  border: 2px dashed grey;
  width: 162px;
  cursor: pointer;
  text-align: left;
  border-radius: 0px !important;
}

.rb-add-fileNotApplies {
  background-color: #FCF5F5;
  box-shadow: none !important;
  min-width: 36px !important;
  height: 42px !important;
  border: 2px dashed grey;
  width: 162px;
  cursor: pointer;
  text-align: left;
  border-radius: 0px !important;
}

.rb-font-75 {
  font-size: 75%;
}

.rb-docs-display {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  i,
  a {
    color: grey;
  }

  .rb-file-label {
    cursor: move;
  }

  .fa.fa-file {
    cursor: move;
  }

  .cdk-drop-list-dragging {
    .rb-file-label {
      color: transparent;
      border: 2px dashed transparent;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

      .fa.fa-file {
        color: transparent;
      }

      .rb-del-doc {
        visibility: hidden !important;
      }
    }
  }
}

.rb-del-doc {
  top: 0px;
  right: 0px;
  visibility: hidden;
}

.rb-file-label:hover {
  .rb-del-doc {
    visibility: visible;
  }
}

.rb-file-label {
  // border: 2px dashed grey;
  // border-radius: 3px;
  padding: 7px;
}

.rb-doc-hdr {
  font-size: 75%;
  top: -20px;
  color: #212529;
}


.rb-section-added {
  border-radius: 3px;
  border: 2px solid #0F9D58;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10) inset;

  .rb-section-title {
    background: #0F9D58;
    color: white;

    .slide-section {
      color: white;
    }
  }

  .rb-section-detail {
    
  
  textarea{
    color: #4FB2D9 !important;
  }

  input{
    color: #4FB2D9 !important;
  }
  mat-slide-toggle{
    color: #4FB2D9 !important;
    
  }
 
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color :#4FB2D9 !important;
  }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label-content {
      color: rgba(0, 0, 0, 0.87);
    }

    .mat-mdc-select-value-text{
      color :#4FB2D9 !important;
    }

    .rb-file-label{
      color :#4FB2D9 !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color :#4FB2D9 !important;
  }

}

.rb-section-removed {
  border-radius: 3px;
  border: 2px dashed #EE4751;
  opacity: 0.25;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10) inset;
  pointer-events: none;

  .rb-section-title {
    background: #EE4751;
    background-color: #EE4751 !important;
    color: white !important;

    .slide-section {
      color: white !important;
    }
  }

  .rb-section-detail {
    color: inherit;
  }
}

.rb-modified {

  span{
    color: #4FB2D9 !important;
  }
 
  textarea{
    color: #4FB2D9 !important;
  }

  input{
    color: #4FB2D9 !important;
  }

  label{
    color: #4FB2D9 !important;
  }

  
  mat-slide-toggle{
    color: #4FB2D9 !important;
    
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color :#4FB2D9 !important;
  }

  color: #4FB2D9 !important;

 .rb-section-body{
  mat-slide-toggle{
    color: #4FB2D9 !important;
  }
 }

 
}

.rb-versions {
  .mat-expansion-panel-header {
    height: 42px !important;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;

    .mat-expansion-indicator {
      visibility: hidden;
    }
  }

  .mat-expanded {
    background: #76D0F4;
    border-bottom: 1px solid #E0E0E0;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 0;
    margin-top: 0;

    .mat-expansion-panel-body {
      padding: 0;
    }

    .rb-accord-content {
      padding: 8px 24px;
      background: white;
      border: 4px solid #76D0F4;
      border-top: 0px;
      border-radius: 8px;
    }
  }
}

.rb-component-holder{
  textarea.mat-mdc-input-element{
    overflow: hidden;
  }
}

.matMenuInput{
  .mat-mdc-form-field-subscript-wrapper{
    display: none !important;
  }

  .mat-mdc-form-field-icon-prefix>.mat-icon{
    padding: 2px 2px 2px 6px !important;
  }

  .mat-mdc-form-field-flex {
    box-shadow: 0 0 0 6px #60CC70;
    border-radius: 4px;
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix{
    padding-bottom: 2px;
    padding-top: 2px;
    min-height: auto;
  }

  .mat-mdc-text-field-wrapper {    
    border: 6px solid #60CC70 !important;
  }
}

.add-menu-widget-item{
  span{
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 14px !important;
    letter-spacing: -0.4px !important;
  }
  min-height: 26px !important;
}


.plus-icon{
  color: #FFF;
  text-align: right;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.4px;
  text-transform: capitalize;
  opacity: 0.3;  
}
