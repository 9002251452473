@import './variables';

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    list-style: none;
}

.pagination li{
    margin-left: 0px;
    display: inline;
    text-transform: none;
    font-size: $body-font-size;
    padding: 3px 0;
    text-align: left;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: $primary-color;
    text-decoration: none;
    background-color: $selected-metal;
    border: 1px solid #ddd;
}

.pagination>li>a:focus, .pagination>li>a:hover{
    color: $selected-metal !important;
    background-color: $primary-hover;
    border-color:$primary-hover;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, 
.pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: $primary-color;
    border-color: $primary-hover;
    color: $selected-metal;
}

.pagination>.disabled>a, 
.pagination>.disabled>a:focus, 
.pagination>.disabled>a:hover, 
.pagination>.disabled>span, 
.pagination>.disabled>span:focus, 
.pagination>.disabled>span:hover {
    color: $disabled-color !important;
    cursor: not-allowed;
    background-color: $background-color;
    border-color: #ddd;
}


