$fa-font-path : '../../node_modules/font-awesome/fonts';

$background-color: white;
$selected-metal: white;
$disabled-color: #777777;
$primary-color: #428bca;
$primary-hover: #357ebd;
$secondary-color: #f0ad4e;
$secondary-hover: #eea236;
$accept-color: #60cc70;
$accept-hover: #0F9D58;
$reject-color: #f44336;
$reject-hover: #d43f3a;
$reject-color2: #e45d53;

$body-font-family:'Karla', sans-serif;
$button-font-family:'Roboto';
$error-font-family:'Roboto';
$primary-font-color: #333333;
$secondary-font-color: #777777;
$title-font-size: 3rem;
$title-font-weight: 400;
$sub-title-font-size: 1.5rem;
$sub-title-font-weight: 400;
$heading-font-size: 2.25rem;
$body-font-size: 1rem;
$star-font-size:25px;
$table-sidebar-font-size:0.875rem;
//bootstrap
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer,
  2: calc($spacer * 2),
  3: calc($spacer * 3),
  4: calc($spacer * 4),
  5: calc($spacer * 5),
  6: calc($spacer * 6),
  7: calc($spacer * 7),
  8: calc($spacer * 8),
  9: calc($spacer * 9),
  10: calc($spacer * 10),
  11: calc($spacer / 5),
  12: calc($spacer / 2),
  dc75: calc($spacer *12 / 16),
  dc625: calc($spacer *10 / 16),
  dc375: calc($spacer *6 / 16),
  dc25: calc($spacer * 4 / 16),
  dc125: calc($spacer * 2 / 16),
  13: calc($spacer * -2),
  14: calc($spacer * -6 / 16),
  15: calc($spacer / 4),
  16: calc($spacer * -10 / 16),
  17: calc($spacer / 3),
  18: calc($spacer * 1.5),
  19: calc($spacer * 3 / 4),
) !default;