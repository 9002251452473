@mixin btn-properties($family, $color, $background, $hover) {
    font-family: $family;
    font-size: 0.88rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $color;
    background-color: $background;
    border-color: $hover;
    border-radius: 2px;
    &:hover {
        color: $color;
        background-color: $hover;
        border-color: $hover;
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: $hover;
    }
}